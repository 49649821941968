import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import CommonLayout from "../../layout/CommonLayout";
import ListFaqComponent from "./ListFaq";
import SEO from "../../seo";

const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

const FAQ = () => {
  return (
    <CommonLayout>
      <SEO title={`FAQ (App)`} />
      <Container>
        <ListFaqComponent />
      </Container>
    </CommonLayout>
  );
};

export default FAQ;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
